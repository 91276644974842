import * as React from "react"
import { Box, Flex } from "theme-ui"
import { Link } from "../Link"
import { Picture } from "../Picture"

export const ProductsTabs = ({ selectedColor, contentModules }) => {
  const [tabId, setTabId] = React.useState(0)
  const [recentlyViewedProducts, setRecentlyViewedProducts] = React.useState([])

  React.useEffect(() => {
    if (selectedColor) {
      const recentlyViewed = JSON.parse(
        localStorage.getItem("tekla::recently_viewed")
      )

      setRecentlyViewedProducts(
        recentlyViewed
          ?.filter((p) => p.id !== selectedColor.objectId)
          .slice(0, 4)
      )
    }
  }, [selectedColor])

  return recentlyViewedProducts?.length ? (
    <Box
      sx={{
        backgroundColor: "grayscale.100",
        paddingInline: 4,
        paddingBlockStart: [null, 30],
        paddingBlockEnd: [12, 21],
      }}
    >
      <Flex sx={{ gap: 5 }}>
        {contentModules && (
          <Link
            onClick={() => setTabId(0)}
            sx={{
              fontSize: ["base", "md"],
              color: tabId !== 0 ? "grayscale.600" : "primary",
              transition: "color .2s",
              marginBlockEnd: 6,
            }}
          >
            Inspiration
          </Link>
        )}
        {recentlyViewedProducts?.length > 1 && (
          <Link
            onClick={() => setTabId(1)}
            sx={{
              fontSize: ["base", "md"],
              color:
                tabId === 1 || !contentModules ? "primary" : "grayscale.600",
              transition: "color .2s",
              marginBlockEnd: 6,
            }}
          >
            Recently viewed
          </Link>
        )}
      </Flex>
      <Box>
        {contentModules && (
          <Box
            sx={{
              display: tabId === 0 ? "block" : "none",
              overflowX: ["scroll", "unset"],
              scrollbarWidth: "none",
              marginInline: -4,

              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {contentModules}
          </Box>
        )}
        <Box
          sx={{
            display: tabId === 1 || !contentModules ? "block" : "none",
            overflowX: ["scroll", "unset"],
            scrollbarWidth: "none",
            marginInline: [-4, 0],

            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {recentlyViewedProducts?.length > 1 && (
            <Flex
              sx={{
                gap: [4, 0],
                overflowX: ["scroll", "unset"],
                marginInline: -2,
                scrollbarWidth: "none",

                "::-webkit-scrollbar": {
                  display: "none",
                },

                "&::before, &::after": {
                  content: "''",
                  display: ["block", "none"],
                  flex: "0 0 8px",
                },
              }}
            >
              {recentlyViewedProducts?.map((p) => {
                return (
                  <Box
                    key={p.id}
                    sx={{
                      flexBasis: [
                        "calc((((100% - 16px) - 16px) - 4px) / 1.5)",
                        "25%",
                      ],
                      flexGrow: 0,
                      flexShrink: 0,
                      paddingInline: [0, 2],
                    }}
                  >
                    <Link to={p.url}>
                      {p.thumbnail?.gatsbyImageData && (
                        <Picture
                          sources={p.thumbnail.gatsbyImageData.images.sources}
                          sx={{ height: "100%" }}
                          imageProps={{
                            width: p.thumbnail.gatsbyImageData.width,
                            height: p.thumbnail.gatsbyImageData.height,
                            sizes:
                              p.thumbnail.gatsbyImageData.images.fallback.sizes,
                            src: p.thumbnail.gatsbyImageData.images.fallback
                              .src,
                            srcSet:
                              p.thumbnail.gatsbyImageData.images.fallback
                                .srcSet,
                            alt: p.title,
                          }}
                        />
                      )}
                    </Link>
                    <Link
                      to={p.url}
                      sx={{ fontSize: ["sm", "base"], marginBlockStart: 1 }}
                    >
                      {p.title}
                    </Link>
                  </Box>
                )
              })}
            </Flex>
          )}
        </Box>
      </Box>
    </Box>
  ) : null
}
